import React, { useDebugValue } from 'react';
import './App.css';
import axios from "../node_modules/axios"
import responseModel from "./responseModel"
import bottomImage from './assets/bottom.PNG';
import logo from './assets/candiyarbakirLogo.PNG';
import moment, { now } from 'moment';
import 'moment/locale/tr';
import dateIcon from './assets/dateicon.PNG';
import hdIcon from './assets/hdicon.PNG';
import bottomVideo from './assets/bottomVideo.mp4';
import $select from 'linq';

class App extends React.Component {
  constructor(props){
    super(props);
    this.state={
      data:[],
      journeyList: [],
      finalData: [],
      componentLoading: false,
      apiCount: 0,
      dateTime: moment(),
      beforeDate: moment(),
      weather: 0,
      city : 'Diyarbakır',
      timezone : 'Asia/Istanbul',
      pageItemCount : 16
    }
  }

  componentWillMount(){
    this.GateDate(this.state.timezone);
    this.bindData();
    setInterval(() => this.bindData(), 300000);
    setInterval(this.clockupdate, 10000); 
  }

  clockupdate = () => {
   this.GateDate(this.state.timezone);
  }

  bindData = () => {
      var destinationIds = [9,10,11,17,19,22,29,30,36,48,69,98,112,1769];
      var originId = 16;
      this.setState( {componentLoading: true,finalData:[],finalJourneyList:[]}, () => {
        destinationIds.forEach(destionationId => {
            this.GetDataFromAPI(originId,destionationId, (finalJourneyList) => {
            this.setState({componentLoading: true,finalData: finalJourneyList}, () => {
              this.setState({componentLoading: false});
            })
          });
        });
        this.GetWeather(this.state.city);
      });
  }

  GateDate(timezone){
    const self = this;
      axios.get('http://worldtimeapi.org/api/timezone/'+ timezone)
      .then(function (response) {
        if(response){
          self.setState({dateTime:moment(response.data.datetime,'YYYY-MM-DD hh:mm:ss'), beforeDate:moment(response.data.datetime,'YYYY-MM-DD hh:mm:ss').add(-2,'h')});
        }else{
          self.setState({dateTime:moment()});
        }
      })
      .catch(function (error) {
        console.log(error);
        self.setState({dateTime:moment()});

      });
  }

  GetWeather(city){
    const self = this;
    axios.get('http://api.openweathermap.org/data/2.5/weather', {
      params: {
        q: city,
        APPID:'39ab257f42049249550e1ac459c9b8bd',
        units:'metric'
      }
    }).then(function (response) {
      if(response){
        self.setState({weather:response.data.main.temp});
      }else{
        self.setState({weather:0});
      }
    })
    .catch(function (error) {
      console.log(error);
      self.setState({weather:0});
    });
  }

  GetDataFromAPI(originId, destinationId, callback){
    const { journeyList } = this.state;
    const self = this;
    const url = "http://candiyarbakirws.accordors.com/api";

    axios.post(url, {
      apiVersion: "0.1.0",
      id: '507220',
      method:'journeys.list',
      params : {
        origin: originId,
        destination: destinationId,
        departure:{
          from: this.state.dateTime.format('YYYY-MM-DDT00:01+02:00'),
          to: this.state.dateTime.format('YYYY-MM-DDT23:59+02:00')
        }
    },      
    headers: { 'Access-Control-Allow-Origin': '*','Content-Type': 'application/json','Response-Type': 'application/json'}
    })
    .then(function (response) {
      if(response){
        var items = response.data.data.items;
          items.forEach((element,index) => {
          var firstElement = [];
          var isOk = false;
          element.route.forEach((routeElement,index) =>{
            if(routeElement.id == originId)
            firstElement = routeElement;
          })          
          
          var lastElement = element.route[element.route.length - 1];
          var model  = new responseModel();
      
          self.GetLicensePlateFromAPI(element.id,index, (licensePlate) => {
            model.licensePlate = licensePlate;
            self.setState({apiCount: self.apiCount + 1});
          });
          
          model.id = element.id;
          model.departureTime = firstElement.time;
          model.orderTime = moment(firstElement.time, 'YYYY-MM-DD HH:mm:ss');
          model.destination = lastElement.name;
          model.arrivalTime = lastElement.time;
          model.busType = element.bus.type;
    
          if (lastElement.id === destinationId.toString() || lastElement.id === "19" || lastElement.id === "48" || lastElement.id === "29") {
            
            var erbilDate = moment(self.state.dateTime.format('YYYY-MM-DD')).add(7,'hour').format('YYYY-MM-DD HH:mm:ss');
            var suleymaniyeDate = moment(self.state.dateTime.format('YYYY-MM-DD')).add(9,'hour').add(30,"minutes").format('YYYY-MM-DD HH:mm:ss');

            var istanbulDate = moment(self.state.dateTime.format('YYYY-MM-DD')).add(15,'hour').add(30,"minutes").format('YYYY-MM-DD HH:mm:ss');

            var firstDate = moment(firstElement.time).format('YYYY-MM-DD HH:mm:ss');

            if(lastElement.id === "29" && firstDate === istanbulDate){
              model.destination = "İSTANBUL";
              isOk = true;
            }

            if(lastElement.id === "19" && firstDate === erbilDate){
              model.destination = "ERBİL";
              isOk = true;
            }

            if(lastElement.id === "48" && firstDate === suleymaniyeDate){
              model.destination = "SÜLEYMANİYE";
              isOk = true;
              journeyList.forEach((element) => {
                if(element.id.includes(model.id.split("-")[0])){
                  isOk=false;
                }
              });

            }
            if(lastElement.id === destinationId.toString()){
              isOk = true;
            }
            if(isOk){
            journeyList.push(model);
            }
          }
         });
         callback(journeyList);
      }
    })
    .catch(function (error) {
      console.log(error);
      callback([]);
    });
  }

  GetLicensePlateFromAPI(journeyId,index, callback){
    const url = "http://candiyarbakirws.accordors.com/api";
    axios.post(url, {
      apiVersion: "0.1.0",
      id: '507220',
      method: "journeys.seats",
      params : {
        journeyId: journeyId
      },
    headers: { 'Access-Control-Allow-Origin': '*','Content-Type': 'application/json','Response-Type': 'application/json'}
    })
    .then(function (response) {
      if(response){
        callback(response.data.data.contact.licensePlate);
      }
    })
    .catch(function (error) {
      console.log(error);
     // callback("GetLicensePlateFromAPI: Hatalı Response");
    });
  }

  render(){
    const date = this.state.dateTime; //moment().locale('tr');
    const { finalData, apiCount } = this.state;
    var sortedData = $select.from(finalData).groupBy(p => p.id).select(p => p.firstOrDefault()).orderBy(p => p.departureTime).toArray();

    if(sortedData.length > this.state.pageItemCount){
      sortedData.forEach((element, index) => {
        if(moment(element.departureTime, 'YYYY-MM-DD hh:mm:ss') < this.state.beforeDate && sortedData.length > this.state.pageItemCount){
          sortedData.splice(index, 1);
        }
      });
    }

    const nextJourney = $select.from(sortedData).where(x=> moment(x.departureTime, 'YYYY-MM-DD hh:mm:ss') > date).firstOrDefault();
    
    return (
      <div className={apiCount + '_test'}>
        {!this.state.componentLoading &&  
        <div>
         <div className="pageHeader">
          <div className="pageHeaderContainer">
            <div className="logoContainer"><img src={logo} alt='logo'  width="550" /></div>
            <div className="infoContainer">
              <div className="dateContainer"><img alt='date' src={dateIcon} /> <span>{this.state.dateTime.format('Do MMMM YYYY')}</span></div>
              <div>
                <div className="smallInfo"><img alt='w' src={hdIcon} /> {parseInt(this.state.weather)}</div>
                <div className="smallInfo">{this.state.dateTime.format('HH:mm')}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="parent">
          <div className='child'>
              <table className="journeyTable tableContainer">
                <thead>
                  <tr>
                    {/* <th>S.NO</th> */}
                    <th>PLAKA</th>
                    <th>OTOBÜS</th>
                    <th>KALKIŞ</th>
                    <th>ŞEHİR</th>
                    <th>VARIŞ</th>
                    <th>DURUM</th>
                  </tr>
                </thead>
                <tbody>
                {
                  
                  sortedData.map((item,index)=>{
                    const kalkmis = date > moment(item.departureTime, 'YYYY-MM-DD hh:mm:ss');
                    let isNext = false;
                    
                    if(nextJourney && nextJourney.id === item.id){
                      var duration = moment.duration(moment(nextJourney.departureTime,'YYYY-MM-DD hh:mm:ss').diff(date));
                      var minutesDiff = duration.asMinutes();
                      isNext = (nextJourney.id === item.id) && (minutesDiff < 30);
                    }

                    let dynClassName = kalkmis ? 'kalkmis':'';
                    dynClassName = dynClassName += isNext ? ' nextJourney': '';

                    return(
                    <tr key={'table_row_' + index}>
                      {/* <td className={kalkmis ? 'kalkmis' : ''}>{index + 1}</td> */}
                      <td className={dynClassName +' left licensePlateColumn'}>{item.licensePlate}</td>
                      <td className={dynClassName + ' busTypeColumn'}>{item.busType}</td>
                      <td className={dynClassName}>{moment(item.departureTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')}</td>
                      <td className={dynClassName +' left'}>{item.destination}</td>
                      <td className={dynClassName}>{moment(item.arrivalTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')}</td>
                      <td className={dynClassName}>{kalkmis ? 'ÇIKTI' : 'BEKLİYOR'}</td>
                    </tr>
                    )
                  })
                }
                </tbody>
              </table> 
            </div>
          </div>
        </div>}
          <div className="bottomImage">
            <div className='floatLeft'><video loop height='280' width='auto' autoPlay muted><source src={(bottomVideo)} type="video/mp4" /></video></div>
            <div className='floatLeft bottomImagepadding'><img height="280" alt='img' src={bottomImage} /></div>
          </div>
      </div>
    ); 
  }
}

export default App;
